import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import yellowVestBetweenTwoTrucks from "../images/yellow-vest-between-two-trucks.jpg";
import talkingInsideAWarehouse from "../images/talking-inside-a-warehouse.jpg";
import {
  DirectionsIcon,
  FeedbackLoopIcon,
  NetworkIcon,
  OffersIcon,
  PieIcon,
  PointTreeIcon,
  ReverseTicksIcon,
  TargetIcon,
  ThreeQuarterClockIcon,
  TrucksWithPalletIcon,
} from "../components/svg-assets/Icons";
import twoRoadsAboveTwoOtherRoads from "../images/two-roads-above-two-other-roads.jpg";
import carDashboard from "../images/car-dashboard.jpg";
import operatorAndTheirTwoScreens from "../images/operator-and-their-two-screens.jpg";
import fretlinkDeskFromTop from "../images/fretlink-desk-from-top.jpg";
import TOSWheel from "../components/svg-assets/TOSWheel";
import TOSMap from "../components/svg-assets/TOSMap";
import TOSGlobe from "../components/svg-assets/TOSGlobe";
import TOSChart from "../components/TOSChart";

const TransportOperatingSystemPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("transport-operating-system.html-title")} />
      <div className="fl-grid fl-top-element">
        <h1 className="fl-big-header fl-both-half">
          <Trans i18nKey="transport-operating-system.title" />
        </h1>
      </div>

      <div className="fl-grid" style={{ marginTop: "130px" }}>
        <div className="fl-first-half fl-green-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="transport-operating-system.first-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="transport-operating-system.first-block.title" />
            </h2>
            <Trans
              parent="p"
              i18nKey="transport-operating-system.first-block.p-fretlink-develops-the-system"
            />
            <Trans
              parent="p"
              i18nKey="transport-operating-system.first-block.p-the-system-rationalize-flows"
            />
            <Trans parent="p" i18nKey="transport-operating-system.first-block.p-all-the-steps" />
          </div>
        </div>
        <div className="fl-very-second-half fl-block-right">
          <TOSWheel />
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "69px" }}>
        <div className="fl-very-first-half fl-svg-width-wrapper">
          <TOSMap />
        </div>
        <div className="fl-second-half fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="transport-operating-system.second-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="transport-operating-system.second-block.title" />
            </h2>
            <Trans
              parent="p"
              i18nKey="transport-operating-system.second-block.p-the-data-collected-on-the-field"
            />
            <Trans
              parent="p"
              i18nKey="transport-operating-system.second-block.p-indeed-a-lack-of-capacity"
            />
          </div>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-all-grid-width fl-resp-sm-scroll-y">
          <TOSChart />
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-pan fl-grid fl-pan--left-soft-gradient">
          <img className="fl-pan-bg" src={twoRoadsAboveTwoOtherRoads} loading="lazy" alt="" />
          <div className="fl-first-third fl-green-context fl-vertical-center">
            <h2>
              <Trans parent="span" i18nKey="transport-operating-system.optimize.step-number" />
              <Trans i18nKey="transport-operating-system.optimize.title" />
            </h2>
          </div>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "80px" }}>
        <div className="fl-centered-half fl-medium-block">
          <h2>
            <Trans i18nKey="transport-operating-system.optimize.we-digitalize-physical-flows" />
          </h2>
          <p className="fl-strong">
            <Trans i18nKey="transport-operating-system.optimize.p-so-we-can-study-shippers-needs" />
          </p>
          <Trans
            parent="p"
            i18nKey="transport-operating-system.optimize.p-technically-its-interoperability"
          />
          <Trans
            parent="p"
            i18nKey="transport-operating-system.optimize.p-sync-with-tools-in-the-market"
          />
        </div>
      </div>

      {
        // This table data is missing. It remains here as commented to
        // let us remember that we should list our partners here.
        /*
        <div className="fl-grid" style={{ marginTop: "80px" }}>
          <div className="fl-both-half fl-medium-block">
            <div
              className="fl-wip"
              style={{ height: "613px", background: "#001A33", borderRadius: 8 }}>
              TODO (table of partners with which we work with)
            </div>
          </div>
        </div>
        */
      }

      <div className="fl-bg-right" style={{ marginTop: "80px" }}>
        <div className="fl-bg-bubble" />
      </div>
      <div className="fl-grid">
        <div className="fl-centered-half fl-medium-block">
          <p className="fl-strong">
            <Trans i18nKey="transport-operating-system.optimize.p-transport-plan-interop-allows-us" />
          </p>
          <ul className="fl-feature-list">
            <li>
              <DirectionsIcon />
              <Trans i18nKey="transport-operating-system.optimize.features.smart-flow-organization" />
            </li>
            <li>
              <ThreeQuarterClockIcon />
              <Trans i18nKey="transport-operating-system.optimize.features.last-km-savings" />
            </li>
            <li>
              <TrucksWithPalletIcon />
              <Trans i18nKey="transport-operating-system.optimize.features.better-loading-rates" />
            </li>
            <li>
              <PieIcon />
              <Trans i18nKey="transport-operating-system.optimize.features.roi-improvements" />
            </li>
          </ul>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-pan fl-grid fl-pan--left-soft-gradient">
          <img className="fl-pan-bg" src={carDashboard} loading="lazy" alt="" />
          <div className="fl-first-third fl-green-context fl-vertical-center">
            <h2>
              <Trans parent="span" i18nKey="transport-operating-system.availability.step-number" />
              <Trans i18nKey="transport-operating-system.availability.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "80px" }}>
        <div className="fl-both-half fl-medium-block">
          <h2>
            <Trans i18nKey="transport-operating-system.availability.we-make-sure-to-understand" />
          </h2>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "32px" }}>
        <div className="fl-first-half fl-medium-block">
          <p className="fl-strong">
            <Trans i18nKey="transport-operating-system.availability.p-it-allows-us-to-suggest" />
          </p>
          <Trans
            parent="p"
            i18nKey="transport-operating-system.availability.p-basically-we-offer-our-parters"
          />
          <Trans
            parent="p"
            i18nKey="transport-operating-system.availability.p-knowledge-of-their-needs"
          />

          <ul className="fl-feature-list">
            <li>
              <TargetIcon />
              <Trans i18nKey="transport-operating-system.availability.features.capacities-visibility" />
            </li>
            <li>
              <OffersIcon />
              <Trans i18nKey="transport-operating-system.availability.features.offers-attractiveness" />
            </li>
            <li>
              <TrucksWithPalletIcon />
              <Trans i18nKey="transport-operating-system.availability.features.catch-rates-guarantee" />
            </li>
            <li>
              <PieIcon />
              <Trans i18nKey="transport-operating-system.availability.features.margin-improvements" />
            </li>
          </ul>
        </div>
        <div className="fl-very-second-half fl-block-right fl-svg-width-wrapper">
          <TOSGlobe />
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-pan fl-grid fl-pan--left-soft-gradient">
          <img className="fl-pan-bg" src={operatorAndTheirTwoScreens} loading="lazy" alt="" />
          <div className="fl-first-third fl-green-context fl-vertical-center">
            <h2>
              <Trans parent="span" i18nKey="transport-operating-system.quality.step-number" />
              <Trans i18nKey="transport-operating-system.quality.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "80px" }}>
        <div className="fl-both-half fl-medium-block">
          <h2>
            <Trans i18nKey="transport-operating-system.quality.once-we-take-over-expeditions" />
          </h2>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "48px" }}>
        <div className="fl-first-half fl-medium-block">
          <p className="fl-strong">
            <Trans i18nKey="transport-operating-system.quality.p-by-ensuring-time" />
          </p>
          <Trans
            parent="p"
            i18nKey="transport-operating-system.quality.p-we-develop-digital-tools"
          />
          <Trans
            parent="p"
            i18nKey="transport-operating-system.quality.p-the-whole-chain-is-covered"
          />
          <p className="fl-strong">
            <Trans i18nKey="transport-operating-system.quality.p-our-digital-tools" />
          </p>

          <ul className="fl-feature-list">
            <li>
              <PointTreeIcon />
              <Trans i18nKey="transport-operating-system.quality.features.transparency" />
            </li>
            <li>
              <NetworkIcon />
              <Trans i18nKey="transport-operating-system.quality.features.task-automation" />
            </li>
            <li>
              <ReverseTicksIcon />
              <Trans i18nKey="transport-operating-system.quality.features.penalty-reductions" />
            </li>
            <li>
              <FeedbackLoopIcon />
              <Trans i18nKey="transport-operating-system.quality.features.improved-decisions" />
            </li>
          </ul>
        </div>
        <div className="fl-last-third fl-vertical-step-list">
          <div className="fl-step-block">
            <code>
              <Trans i18nKey="transport-operating-system.quality.teams.coordinators.number" />
            </code>
            <h3>
              <Trans i18nKey="transport-operating-system.quality.teams.coordinators.title" />
            </h3>
            <Trans
              parent="p"
              i18nKey="transport-operating-system.quality.teams.coordinators.description"
            />
          </div>
          <div className="fl-step-block">
            <code>
              <Trans i18nKey="transport-operating-system.quality.teams.ops.number" />
            </code>
            <h3>
              <Trans i18nKey="transport-operating-system.quality.teams.ops.title" />
            </h3>
            <Trans parent="p" i18nKey="transport-operating-system.quality.teams.ops.description" />
          </div>
          <div className="fl-step-block">
            <code>
              <Trans i18nKey="transport-operating-system.quality.teams.quality-dept.number" />
            </code>
            <h3>
              <Trans i18nKey="transport-operating-system.quality.teams.quality-dept.title" />
            </h3>
            <Trans
              parent="p"
              i18nKey="transport-operating-system.quality.teams.quality-dept.description"
            />
          </div>
          <div className="fl-step-block">
            <code>
              <Trans i18nKey="transport-operating-system.quality.teams.transport-managers.number" />
            </code>
            <h3>
              <Trans i18nKey="transport-operating-system.quality.teams.transport-managers.title" />
            </h3>
            <Trans
              parent="p"
              i18nKey="transport-operating-system.quality.teams.transport-managers.description"
            />
          </div>
        </div>
      </div>

      <div className="fl-grid fl-three-cards" style={{ marginTop: "104px" }}>
        <Link to="/carriers/">
          <img className="fl-illustration" src={yellowVestBetweenTwoTrucks} alt="" />
          <h2>
            <Trans i18nKey="transport-operating-system.what-next.carriers.title" />
          </h2>
          <Trans parent="p" i18nKey="transport-operating-system.what-next.carriers.description" />
          <p className="fl-link-next">
            <Trans i18nKey="transport-operating-system.what-next.carriers.learn-more" />
          </p>
        </Link>
        <Link to="/shippers/">
          <img className="fl-illustration" src={talkingInsideAWarehouse} loading="lazy" alt="" />
          <h2>
            <Trans i18nKey="transport-operating-system.what-next.shippers.title" />
          </h2>
          <Trans parent="p" i18nKey="transport-operating-system.what-next.shippers.description" />
          <p className="fl-link-next">
            <Trans i18nKey="transport-operating-system.what-next.shippers.learn-more" />
          </p>
        </Link>
        <Link to="/about-us/">
          <img className="fl-illustration" src={fretlinkDeskFromTop} loading="lazy" alt="" />
          <h2>
            <Trans i18nKey="transport-operating-system.what-next.fretlink.title" />
          </h2>
          <Trans parent="p" i18nKey="transport-operating-system.what-next.fretlink.description" />
          <p className="fl-link-next">
            <Trans i18nKey="transport-operating-system.what-next.fretlink.learn-more" />
          </p>
        </Link>
      </div>
    </Layout>
  );
};

export default TransportOperatingSystemPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
