import React from "react";
import { CalendarIcon, GearLoop, ReverseTicksIcon } from "./svg-assets/Icons";

import stylesheet from "./TOSChart.module.css";
import { Trans } from "gatsby-plugin-react-i18next";

export default function TOSChart() {
  return (
    <div className={stylesheet.tosChart}>
      <div className={stylesheet.processSteps}>
        <div className={stylesheet.processStep}>
          <div className={stylesheet.icon}>
            <GearLoop />
          </div>
          <Trans i18nKey="transport-operating-system.chart.process-steps.optimize" />
        </div>
        <div className={stylesheet.processStep}>
          <div className={stylesheet.icon}>
            <CalendarIcon />
          </div>
          <Trans i18nKey="transport-operating-system.chart.process-steps.availability" />
        </div>
        <div className={stylesheet.processStep}>
          <div className={stylesheet.icon}>
            <ReverseTicksIcon />
          </div>
          <Trans i18nKey="transport-operating-system.chart.process-steps.quality" />
        </div>
      </div>
      <section className={stylesheet.greenGoal}>
        <h4 className={stylesheet.goalCategory}>
          <Trans i18nKey="transport-operating-system.chart.business-goals.title" />
        </h4>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.business-goals.optimize" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.business-goals.availability" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.business-goals.quality" />
        </div>
      </section>
      <hr />
      <section className={stylesheet.greenGoal}>
        <h4 className={stylesheet.goalCategory}>
          <Trans i18nKey="transport-operating-system.chart.key-tech.title" />
        </h4>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.key-tech.optimize" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.key-tech.availability" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.key-tech.quality" />
        </div>
      </section>
      <section className={stylesheet.blueGoal}>
        <h4 className={stylesheet.goalCategory}>
          <Trans i18nKey="transport-operating-system.chart.improvements.title" />
        </h4>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.improvements.optimize" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.improvements.availability" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.improvements.quality" />
        </div>
      </section>
      <hr />
      <section className={stylesheet.blueGoal}>
        <h4 className={stylesheet.goalCategory}>
          <Trans i18nKey="transport-operating-system.chart.time-savings.title" />
        </h4>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.time-savings.optimize" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.time-savings.availability" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.time-savings.quality" />
        </div>
      </section>
      <hr />
      <section className={stylesheet.blueGoal}>
        <h4 className={stylesheet.goalCategory}>
          <Trans i18nKey="transport-operating-system.chart.budget-control.title" />
        </h4>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.budget-control.optimize" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.budget-control.availability" />
        </div>
        <div className={stylesheet.goal}>
          <Trans i18nKey="transport-operating-system.chart.budget-control.quality" />
        </div>
      </section>
    </div>
  );
}
